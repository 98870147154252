import React from 'react';

const Map = () => {
    return (
        <div>
            
        </div>
    );
}

export default Map;
